.id_task_detail{
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  .id_task_detail_in{
    width: 33%;
    box-sizing: border-box;
  }
}
.two_text{
  margin-top: 2%;
  display: flex;
  .for_name{
    font-size: 19px;
    font-weight: 500;
  }
  .for_data{
    margin-left: 5px;
    font-size: 19px;
  }
}
.pdf-file-download-deatil{
  height: 35px;
  border: 1px solid black;
  border-radius: 7px;
  display: flex;
  align-items: center;
  .anticon{
    margin: 0 10px 0 0;
    font-size: 30px;
  }
}
.done_task{
  margin-top: 2%;
  border: 1px solid;
  width:65%;
  border-radius: 10px;
  height: 37px;
}
.for-message{
  .container{
    .row{
      .col-12{
        .card{
          width: 100%;
          min-height: 200px;
          background-color: #F0F2F5;
          padding: 20px;
          .message{
            font-size: 25px;
            font-weight: 500;
            text-align: center;
          }

        }
      }
    }
  }
  .container-fluid{
    .row{
      .col-12{
        margin-top: 3%;
        .card{
          overflow: auto;
          width: 100%;
          background-color: #F0F2F5;
          padding: 20px;
          .message{
            font-size: 25px;
            font-weight: 500;
            text-align: center;
          }
          .message_place{
            position: relative;
            width: 100%;
            height: 40px;
            background-color: white;
            margin-top: 2%;
            .fileUpload{
              position: absolute;
              .label-Up{
                position: absolute;
                border-right: 1px solid #b1b3b6;
                width: 40px;
                height: 40px;
                .anticon{
                  font-size: 30px;
                  margin-top: 15%;
                  margin-left: 12%;
                }
              }
              .fileUload{
                opacity: 0;
                position: absolute;
                width: 40px;
                height: 40px;
              }
            }
            .sentI{
              height: 40px;
              padding: 0 70px 0 45px;
              box-shadow: none;
            }
            .send_message{
              cursor: pointer;
              position: absolute;
              margin-left: 97.5%;
              margin-top:-35px;
            }
          }
          .for_message_reality{
            max-height: 600px;
            overflow:auto;
            .isMe{
              font-size: 18px;
              font-weight: 400;
              padding: 10px 40px;
              display: flex;
              width: 100%;
              align-items:end;
              p{
                color: white;
                border-radius:15px 15px 15px 0;
                background-color: #a6a8ab;
                min-width: 2%;
                min-height: 5px;
                padding: 4px 16px!important;
              }
              .NameF{
                background-color: #a6a8ab;
                max-width: 25px;
                max-height: 25px;
                min-width: 25px;
                min-height: 25px;
                border-radius: 50%;
                font-size: 10px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px 0 0;
              }
            }
            .isYou{
              font-size: 18px;
              font-weight: 400;
              padding: 0!important;
              justify-content: end;
              display: flex;
              width: 100%;
              min-height: 20px;
              align-items: flex-end;
              p{
                color: white;
                border-radius:15px 15px 0 15px!important;
                background-color: #545558;
                min-width: 2%;
                min-height: 5px;
                padding: 4px 16px!important;
              }
              .NameF1{
                font-size: 10px;
                background-color: #545558;
                max-width: 25px;
                max-height: 25px;
                min-width: 25px;
                min-height: 25px;
                border-radius: 50%;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 5px 0 0;
              }
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1200px) and (max-width: 1440px) {
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }

          }
        }
      }
    }
    .container-fluid{
      .row{
        .col-12{
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              .fileUpload{
                position: absolute;
                .fileUload{
                  opacity: 0;
                  position: absolute;
                  width: 40px!important;
                  height: 40px;
                  cursor: pointer;
                }
                .label-Up{
                  position: absolute;
                  border-right: 1px solid #b1b3b6;
                  width: 40px;
                  height: 40px;
                  .anticon{
                    font-size: 30px;
                    margin-top: 15%;
                  }
                }
              }
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 95%;
                margin-top:-34px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .id_task_detail{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    .id_task_detail_in{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
    .container-fluid{
      .row{
        .col-12{
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 95%;
                margin-top:-35px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .id_task_detail{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    .id_task_detail_in{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
    .container-fluid{
      .row{
        .col-12{
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 90%;
                margin-top:-34px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .id_task_detail{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    .id_task_detail_in{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
    .container-fluid{
      .row{
        .col-12{
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 90%;
                margin-top:-34px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 378px) and (max-width: 576px) {
  .pdf-file-download-deatil{
    margin: -4px 7px 5px 7px;
    border-radius: 7px;
    border: 1px solid black;
    height: 35px;
    display: flex;
    align-items: center;
    background-color: transparent;
    .anticon{
      margin: 0 10px 0 0;
      font-size: 20px;
    }
  }
  .id_task_detail{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    .id_task_detail_in{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .two_text{
    margin-top: 2%;
    display: flex;
    .for_name{
      font-size: 15px;
      font-weight: 500;
    }
    .for_data{
      margin-left: 5px;
      font-size: 15px;
    }
  }
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
    .container-fluid{
      position: relative;
      .row{
        .col-12{
          margin-left: -5.9%;
          min-width: 113%;
          position: absolute;
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 85%;
                margin-top:-34px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 200px) and (max-width: 378px) {
  .id_task_detail{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    .id_task_detail_in{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .for-message{
    .container{
      .row{
        .col-12{
          .card{
            width: 100%;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
    .container-fluid{
      .row{
        .col-12{
          margin-top: 3%;
          .card{
            width: 100%;
            background-color: #F0F2F5;
            max-height: 450px!important;
            padding: 20px;
            .message{
              font-size: 25px;
              font-weight: 500;
              text-align: center;
            }
            .message_place{
              position: relative;
              width: 100%;
              height: 40px;
              background-color: white;
              margin-top: 2%;
              input{
                height: 40px;
                padding: 0 70px 0 10px;
                box-shadow: none;
              }
              .send_message{
                cursor: pointer;
                position: absolute;
                margin-left: 87%;
                margin-top:-34px;
              }
            }
            .for_message_reality{
              max-height: 400px;
              overflow: auto;
              .isMe{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                display: flex;
                width: 100%;
                align-items:end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #a6a8ab;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF{
                  background-color: #a6a8ab;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  font-size: 10px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 10px 0 0;
                }
              }
              .isYou{
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px;
                justify-content: end;
                display: flex;
                width: 100%;
                min-height: 20px;
                align-items: flex-end;
                p{
                  color: white;
                  border-radius: 15px 15px 15px 0;
                  background-color: #545558;
                  min-width: 2%;
                  min-height: 5px;
                  padding: 6px;
                }
                .NameF1{
                  font-size: 10px;
                  background-color: #545558;
                  max-width: 25px;
                  max-height: 25px;
                  min-width: 25px;
                  min-height: 25px;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.pdf-file-download{
  margin: 10px 7px;
  border-radius: 7px;
  border: 1px solid white;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  .anticon{
    margin: 0 10px 0 0;
    font-size: 23px;
  }
}