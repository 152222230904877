.my_tasks_new{
  .container-fluid{
    .fort-task-count{
      width: 100%;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
    }
    .row{
      .card{

        border: none!important;
        padding: 10px 15px 15px 10px;
        border-radius: 15px;
        background-color: #F0F2F5;
        transition: .1s linear;
        &:hover{
          transition: .1s linear;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }
        .for_title{
          font-weight: 500;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          .read-img{
            width: 20px;
          }
        }
        .for-description{
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient:vertical;
          overflow: hidden;
          margin-top: 2%;
          font-weight: 500;
          font-size: 15px;
        }
        .import_end{
          display: flex;
          justify-content:space-between;
          .importance{
            font-weight: 500;
          }
          .end_date{
            font-weight: 500;
          }
        }
      }
    }
  }
}
