a{
  text-decoration: none!important;
}
body{
  font-family: 'Open Sans', sans-serif!important;
  background-color: white!important;
}
.drop_menu{
  display: none;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;
  &:hover{
    color: #108ee9;
  }
}
.for-logo{
  display: flex;
  height: 70px;
  border-radius: 6px;
  margin: 16px;
  justify-content: center;
  img{
    width: 70px;
    height: 70px;
  }
}
.for-logo1{
  display: flex;
  height: 50px;
  border-radius: 6px;
  margin: 16px;
  justify-content: center;
  img{
    width: 50px;
    height: 50px;
  }
}
.burger_big{
  display: none;
}
.site-layout-background1{
  box-shadow: 0 1px 4px #00152914!important;
}
.my_profile{
  display: flex;
  margin-left: 85%;
  color: white;
  .name{
    margin-left: 3%;
  }
}
.ant-layout-sider-dark{
  min-width: 250px!important;
}
.antLoyaut{
  min-width: 30px!important;
}
.icon-antd{
  font-size: 25px!important;
  margin: 20px 7px!important;
}
.icon-antd2{
  font-size: 25px!important;
}
.menu-main-item{
  width: 250px!important;
  font-weight: 500!important;
  display: flex;
  align-items: center!important;
}
.ant-layout-sider-dark{
  &::-webkit-scrollbar{
    width: 0px!important;
  }
}
.site-layout-background{
  width: 100%;
  height: 89vh!important;
  background-color: white !important;
  border-radius: 10px;
  &::-webkit-scrollbar{
    display: none!important;
    width: 0!important;
    height: 0!important;
  }
}
//#c9c6c9
.site-layout{
  margin-left: 250px;
  height:100vh!important;
  background-color: #F0F2F5 !important;
}
.content_main{
  border-radius: 10px;
  //border: 1.3px solid black;
  margin: 24px 16px 0!important;
}
.for-link{
  display: flex;
  p{
   margin: 12px 7px;
  }
  p1{
    font-family: 'Open Sans', sans-serif!important;
    margin: 12px 15px;
  }
}

.for-link2{
  display: flex;
  p{
    display: none;
    margin: 12px 7px;
  }
  p1{
    display: none;
    margin: 12px 15px;
  }
}
.ant-menu-title-content{
  margin-left: -30px;
}
.ant-menu-submenu-title{
  margin-left: -23px;
}
.ant-menu-submenu-arrow{
  &::after{
    margin-left: -4px;
  }
  &::before{
    margin-left: -4px;
  }
}
@media (min-width: 1200px) and (max-width:1440px){
  .ant-menu-submenu-title{
    margin-left: -23px;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 14px;
    }
    &::before{
      margin-left: 14px;
    }
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 200px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 200px!important;
  }
  .site-layout{
    margin-left: 200px;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}
@media (min-width: 992px) and (max-width:1200px){
  .my_profile{
    display: flex;
    margin-left: 70%;
    color: white;
    .name{
      margin-left: 3%;
    }
  }
  .ant-menu-submenu-title{
    margin-left: -23px;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 14px;
    }
    &::before{
      margin-left: 14px;
    }
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 200px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 200px!important;
  }
  .site-layout{
    margin-left: 200px;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}
@media (min-width: 768px) and (max-width:992px){
  .my_profile{
    display: flex;
    margin-left: 65%;
    color: white;
    .name{
      display: block;
      margin-left: 3%;
    }
  }

  .ant-menu-submenu-title{
    margin-left: -23px;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 14px;
    }
    &::before{
      margin-left: 14px;
    }
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 200px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 200px!important;
  }
  .site-layout{
    margin-left: 200px;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}
@media (min-width: 576px) and (max-width:768px){
  .burger_big{
      display: block;
      .burger_child{
        display: flex;
        .for_burger{
          .anticon{
            margin-left: 45%;
            font-size: 20px;
            color: white!important;
          }
        }
        .my_profile{
          display: flex;
          margin-left: 65%;
          color: white;
        }
      }
    }
  .in_my_profile{
    display: none;
  }
  .drop_menu{
    margin-top:63px;
    display: block;
    position: relative;
    .body-collapse{
      margin-top: 30.4%;
      position: absolute;
      z-index: 2;
      .ant-menu-dark{
        min-height: 100vh!important;
      }
    }
  }
  .ant-menu-submenu-title{
    margin-left: -23px;
    font-size: 11px;
    font-weight: 400;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 13px;
    }
    &::before{
      margin-left: 13px;
    }
  }
  .ant-menu-submenu{
    width:180px!important; ;
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 180px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 100px!important;
  }
  .ant-layout-sider{
    width: 180px!important;
    display: none;
  }
  .site-layout{
    margin-left: 0;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}
@media (min-width: 378px) and (max-width:600px) {
  .burger_big{
    display: block;
    .burger_child{
      display: flex;
      box-sizing: border-box;
      .for_burger{
        .anticon{
          margin-left: 45%;
          font-size: 20px;
          color: white!important;
        }
      }
      .my_profile{
        box-sizing: border-box;
        margin-left: 45%;
        display: flex;
        color: white;
      }
    }
  }
  .in_my_profile{
    display: none;
  }
  .drop_menu{
    margin-top:63px;
    display: block;
    position: relative;
    .body-collapse{
      margin-top: 30.4%;
      position: absolute;
      z-index: 2;
      .ant-menu-dark{
        min-height: 100vh!important;
      }
    }
  }
  .ant-menu-submenu-title{
    margin-left: -23px;
    font-size: 11px;
    font-weight: 400;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 13px;
    }
    &::before{
      margin-left: 13px;
    }
  }
  .ant-menu-submenu{
    width:180px!important; ;
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 180px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 100px!important;
  }
  .ant-layout-sider{
    width: 180px!important;
    display: none;
  }
  .site-layout{
    margin-left: 0;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}
@media (min-width: 190px) and (max-width:378px) {
  .burger_big{
    display: block;
    .burger_child{
      display: flex;
      .for_burger{
        .anticon{
          margin-left: 45%;
          font-size: 20px;
          color: white!important;
        }
      }
      .my_profile{
        display: flex;
        margin-left: 50%;
        color: white;
      }
    }
  }
  .drop_menu{
    margin-top:63px;
    display: block;
    position: relative;
    .body-collapse{
      margin-top: 30.4%;
      position: absolute;
      z-index: 1;
      .ant-menu-dark{
        min-height: 100vh!important;
      }
    }
  }
  .ant-menu-submenu-title{
    margin-left: -23px;
    font-size: 11px;
    font-weight: 400;
  }
  .ant-menu-submenu-arrow{
    &::after{
      margin-left: 13px;
    }
    &::before{
      margin-left: 13px;
    }
  }
  .ant-menu-submenu{
    width:180px!important; ;
  }
  .site-layout-background{
    width: 100%;
    height: 84vh!important;
    background-color: white !important;
    border-radius: 10px;
    &::-webkit-scrollbar{
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
  }
  .menu-main-item{
    margin-left: -20px;
    width: 180px!important;
    font-weight: 500!important;
    display: flex;
    align-items: center!important;
    font-size: 14px!important;
  }
  .icon-antd {
    font-size: 20px !important;
    margin: 20px 0px  20px 10px!important;
  }
  .ant-layout-sider-dark{
    min-width: 100px!important;
  }
  .ant-layout-sider{
    width: 180px!important;
    display: none;
  }
  .site-layout{
    margin-left: 0;
    height:100vh!important;
    background-color: #F0F2F5 !important;
  }
  .for-link{
    display: flex;
    p{
      margin: 12px 7px;
      font-size: 13px!important;
    }
    p1{
      margin: 12px 15px;
      font-size: 11px!important;
    }
  }
}

