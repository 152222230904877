.ant-select-selection-search-input{
  border: none!important;
}
.in-media{
  display: none;
}
.ant-select-selector{
  box-shadow: none!important;
  border: 1px solid darkgray!important;
  border-radius: 8px!important;
  max-width: 300px;
  max-height: 40px!important;
  overflow-x: auto;
  &::-webkit-scrollbar{
    display: none!important;
    width: 0!important;
    height: 0!important;
  }
  &::-webkit-scrollbar-track{
    display: none!important;
    width: 0!important;
    height:0!important;
  }
  &::-webkit-scrollbar-thumb {
    display: none!important;
    width: 0!important;
    height: 0!important;
  }
  &:hover{
    outline: none!important;
    border: 1px solid darkgray!important;
    border-radius: 8px!important;
  }
}

.for_success{
  border-radius: 15px;
  font-size: 27px;
  font-weight: 500;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 30%;
  flex-direction:column;
  z-index: 1;
  position: absolute;
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .for-icon{
    font-size: 30px;
  }
}
.post_assignmant{
  .for-post-text{
    text-align: center;
    font-weight: 500;
    font-size: 25px;
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%;
      width: 70%;
      min-height:500px;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        .navbar{
          width: 100%;
          height: 10%;
        }
        .form{
          display: flex;
          justify-content: space-around;
          .first_input{
            font-family: 'Open Sans', sans-serif!important;
            margin-top: 2%;
            color: black!important;
            font-size: 16px!important;
            .react-datepicker__input-container{
              input{
                font-family: 'Open Sans', sans-serif!important;
                outline: none!important;
                border: 1px solid #A9A9A9;
                width: 300px;
                border-radius: 8px!important;
                padding:0 10px!important;
                height: 40px;
                box-shadow: none!important;
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
              }
            }
            .for-control-input{
              padding: 0!important;
              height: 40px;
              font-family: 'Open Sans', sans-serif!important;
              color: black!important;
              font-size: 16px!important;
            }
            .ant-input-affix-wrapper{
              font-family: 'Open Sans', sans-serif!important;
              border: 1px solid #A9A9A9!important;
              width: 300px;
              border-radius: 8px!important;
              padding:0 10px!important;
              height: 40px;
              box-shadow: none!important;
              color: black!important;
              font-size: 16px!important;
              &:hover{
                border: 1px solid #A9A9A9;
              }
            }
            .ant-input-affix-wrapper1{
              font-family: 'Open Sans', sans-serif!important;
              border: 1px solid #A9A9A9!important;
              width: 300px;
              border-radius: 8px!important;
              height: 40px;
              box-shadow: none!important;
              color: black!important;
              font-size: 15px!important;
              &:hover{
                border: 1px solid #A9A9A9;
              }
            }
            .ant-input-affix-wrapper-focused{
              border: 1px solid #A9A9A9;
            }
            .ant-select-selector{
              font-family: 'Open Sans', sans-serif!important;
              border-radius: 8px!important;
              padding:0 10px!important;
              height: 40px;
              color: black!important;
              font-size: 15px!important;
            }
            p{
              margin-top: 10px;
            }
            //input{
            //  padding: 3px 5px;
            //  margin-top: .4%;
            //  //border: 1px solid darkgray;
            //  // width: 300px;
            //  //height: 40px;
            //  outline: none!important;
            //  border-radius: 8px!important;
            //}
            textarea{
              padding: 6px 5px;
              border: 1px solid #A9A9A9;
              outline: none;
              height: 40px;
              border-radius: 8px;
              width: 300px;
              color: black!important;
              font-size: 15px;
              font-family: 'Open Sans', sans-serif!important;
              &::placeholder{
                font-size: 15px;
                color: #b6b6b6;
              }
            }
            select{
              font-family: 'Open Sans', sans-serif!important;
              padding: 3px 5px;
              margin-top: .4%;
              border: 1px solid darkgray;
              width: 300px;
              height: 40px;
              outline: none!important;
              border-radius: 8px;
            }
          }
        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 45px;
            margin-top: 6%;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1440px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 25%;
    flex-direction:column;
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        .navbar{
          width: 100%;
          height: 7%;
        }
        .form{
          display: flex;
          justify-content: space-around;
          .first_input{
            font-size: 17px;
            margin-top: 2%;
            .react-datepicker__input-container{
              input{
                outline: none!important;
                border: 1px solid #A9A9A9;
                width: 300px;
                border-radius: 8px!important;
                padding:0 10px!important;
                height: 30px!important;
                box-shadow: none!important;
              }
            }
            .ant-input-affix-wrapper{
              border: 1px solid #A9A9A9!important;
              width: 300px;
              border-radius: 8px!important;
              padding:0 10px!important;
              height: 30px!important;
              box-shadow: none!important;
              &:hover{
                border: 1px solid #A9A9A9;
              }
            }
            .ant-input-affix-wrapper1{
              border: 1px solid #A9A9A9!important;
              width: 300px;
              border-radius: 8px!important;
              height: 30px!important;
              box-shadow: none!important;
              &:hover{
                border: 1px solid #A9A9A9;
              }
            }
            .ant-input-affix-wrapper-focused{
              border: 1px solid #A9A9A9;
            }
            .ant-select-selector{
              border-radius: 8px!important;
              padding:0 10px!important;
              height: 30px!important;
            }
            p{
              margin-top: 10px;
            }
            //input{
            //  padding: 3px 5px;
            //  margin-top: .4%;
            //  //border: 1px solid darkgray;
            //  // width: 300px;
            //  //height: 40px;
            //  outline: none!important;
            //  border-radius: 8px!important;
            //}
            textarea{
              padding: 6px 5px;
              border: 1px solid #A9A9A9;
              outline: none;
              height: 30px!important;
              border-radius: 8px;
              width: 300px;
              &::placeholder{
                font-size: 15px;
                color: #b6b6b6;
              }
            }
            select{
              padding: 3px 5px;
              margin-top: .4%;
              border: 1px solid darkgray;
              width: 300px;
              height: 30px;
              outline: none!important;
              border-radius: 8px;
            }
          }
        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width:1200px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 25%;
    flex-direction:column;
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        .navbar{
          width: 100%;
          height: 7%;
        }
        .form{
          display:flex;
          .in-media{
            display: block;
          }
          .d-block-big-media{
            display: none;
          }

        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width:992px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 25%;
    flex-direction:column;
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        .navbar{
          width: 100%;
          height: 7%;
        }
        .form{
          display:flex;
          .in-media{
            display: block;
          }
          .d-block-big-media{
            display: none;
          }

        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width:768px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 25%;
    flex-direction:column;
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        .navbar{
          width: 100%;
          height: 7%;
        }
        .form{
          display:flex;
          .in-media{
            display: block;
          }
          .d-block-big-media{
            display: none;
          }

        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 378px) and (max-width:576px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 5%;
    flex-direction:column;
    text-align: center;
    z-index: 1;
    position: absolute;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    hr{
      display: none;
    }
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: transparent!important;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid transparent!important;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        .navbar{
          display: none;
        }
        .form{
          display:flex;
          .in-media{
            display: block;
          }
          .d-block-big-media{
            display: none;
          }

        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 200px) and (max-width:378px){
  .for_success{
    border-radius: 15px;
    font-size: 27px;
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 25%;
    flex-direction:column;
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .for-icon{
      font-size: 30px;
    }
  }
  .user_post_task{
    display: flex;
    justify-content: center;
    .green_zone{
      margin-top: 3%!important;
      width:90% !important;
      min-height:430px!important;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;
      .in_green_zone{
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center!important;
        .navbar{
          width: 100%;
          height: 7%;
        }
        .form{
          display:flex;
          .in-media{
            display: block;
          }
          .d-block-big-media{
            display: none;
          }

        }
        .for-add-button{
          button{
            outline: none!important;
            border: none;
            width: 300px;
            height: 35px!important;
            margin-top: 4% !important;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

