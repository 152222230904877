.add_user_main {
  .for-post-text {
    text-align: center;
    font-weight: 500;
    font-size: 25px;
  }

  .user_employee_task {
    display: flex;
    justify-content: center;

    .green_zone {
      margin-top: 3%;
      width: 70%;
      height: 500px;
      background-color: #F0F2F5;
      padding: 30px;
      border-radius: 10px;

      .in_green_zone {
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid green;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 20px;

        .navbar {
          width: 100%;
          height: 10%;
        }

        .form {
          .media-in-block{
            display: none;
          }
          .add_user_in_form_employee {
            display: flex;
            justify-content: space-around;
            .first_input {
              margin-top: 2%;
              color: black!important;
              font-family: 'Open Sans', sans-serif!important;
              font-size: 15px!important;
              p{
                margin-top: 10px;
              }
              .select{
                padding: 3px 5px;
                margin-top: .4%;
                border: 1px solid darkgray;
                width: 300px;
                height: 40px;
                outline: none !important;
                border-radius: 8px;
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
              }
              .ant-select-selector{
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
                border: none!important;
                &:hover{
                  border: none!important;
                }
              }
              input {
                padding: 3px 5px;
                margin-top: .4%;
                border: 1px solid darkgray;
                width: 300px;
                height: 40px;
                outline: none !important;
                border-radius: 8px;
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
              }

              textarea {
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
                padding: 3px 5px;
                border: 1px solid darkgray;
                outline: none;
                height: 40px;
                border-radius: 8px;
              }

              select {
                color: black!important;
                font-family: 'Open Sans', sans-serif!important;
                font-size: 15px!important;
                padding: 3px 5px;
                margin-top: .4%;
                border: 1px solid darkgray;
                width: 300px;
                height: 40px;
                outline: none !important;
                border-radius: 8px;
              }
            }
          }
        }
        .for_add_button{
          display: flex;
          justify-content: center;
          button{
            width: 300px;
            height: 45px;
            border: none;
            border-radius: 10px;
            margin-top: 12%;
          }
        }
      }
    }
  }
}


@media (min-width: 1200px) and (max-width: 1440px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      display: flex;
      justify-content: center;

      .green_zone {
        margin-top: 3%;
        width: 70%;
        min-height: 500px;
        background-color: #F0F2F5;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid green;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
            width: 100%;
            height: 10%;
          }

          .form {
            .add_user_in_form_employee {
              display: flex;
              justify-content:space-around;
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 40px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 300px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 250px;
              height: 45px;
              border: none;
              border-radius: 10px;
              margin-top: 12%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1130px) and (max-width: 1200px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      display: flex;
      justify-content: center;
      .green_zone {
        margin-top: 3%;
        width: 85%;
        min-height: 500px;
        background-color: #F0F2F5;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid green;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
            width: 100%;
            height: 10%;
          }
          .form {
            display: flex;
            .add_user_in_form_employee {
              display: flex!important;
              justify-content:space-between!important;
              .in_in_1{
                margin-left: 26%;
              }
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 40px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 300px;
                  height: 40px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 250px;
              height: 45px;
              border: none;
              border-radius: 10px;
              margin-top: 12%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 990px) and (max-width: 1130px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      display: flex;
      justify-content: center;
      .green_zone {
        margin-top: 3%;
        width: 85%;
        min-height: 500px;
        background-color: #F0F2F5;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid green;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
            width: 100%;
            height: 10%;
          }
          .form {
            display: flex;
            .add_user_in_form_employee {
              display: flex!important;
              justify-content:space-between!important;
              .in_in_1{
                margin-left: 5%;
              }
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 30px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 300px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 250px;
              height: 35px;
              border: none;
              border-radius: 10px;
              margin-top: 18%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      display: flex;
      justify-content: center;
      .green_zone {
        margin-top: 3%;
        width: 95%;
        min-height: 500px;
        background-color: #F0F2F5;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid green;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
            width: 100%;
            height: 10%;
          }
          .form {
            display: flex;
            .add_user_in_form_employee {
              display: flex!important;
              justify-content:space-between!important;
              .in_in_1{
                margin-left: 14%;
              }
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 30px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 200px;
              height: 35px;
              border: none;
              border-radius: 10px;
              margin-top: 22%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      display: flex;
      justify-content: center;
      .green_zone {
        margin-top: 3%;
        width: 95%;
        min-height: 500px;
        background-color: #F0F2F5;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid green;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
            width: 100%;
            height: 10%;
          }
          .form {
            display: flex;
            .add_user_in_form_employee {
              display: flex!important;
              justify-content:space-between!important;
              .in_in_1{
                margin-left: 14%;
              }
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 30px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 200px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 200px;
              height: 35px;
              border: none;
              border-radius: 10px;
              margin-top: 22%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 378px) and (max-width: 576px) {
  .add_user_main {
    .for-post-text {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }

    .user_employee_task {
      hr{
        display: none;
      }
      display: flex;
      justify-content: center;
      .green_zone {
        margin-top: 3%;
        width: 95%;
        min-height: 500px;
        background-color: transparent!important;
        padding: 30px;
        border-radius: 10px;

        .in_green_zone {
          width: 100%;
          height: 100%;
          background-color: white;
          border: 1px solid transparent!important;
          border-radius: 10px;
          padding: 0 20px;
          font-size: 20px;
          .navbar {
           display: none;
          }
          .form {
            .media-in-block{
              display: block!important;
              .first_input {
                margin-top: 2%;
                p{
                  margin-top: 10px;
                }
                .select{
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
                .ant-select-selector{
                  border: none!important;
                  &:hover{
                    border: none!important;
                  }
                }
                input {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }

                textarea {
                  padding: 3px 5px;
                  border: 1px solid darkgray;
                  outline: none;
                  height: 30px;
                  border-radius: 8px;
                }

                select {
                  padding: 3px 5px;
                  margin-top: .4%;
                  border: 1px solid darkgray;
                  width: 250px;
                  height: 30px;
                  outline: none !important;
                  border-radius: 8px;
                }
              }
            }
            display: flex;
            .add_user_in_form_employee {
              display: none!important;
            }
          }
          .for_add_button{
            display: flex;
            justify-content: center;
            button{
              width: 250px;
              height: 35px;
              border: none;
              border-radius: 10px;
              margin-top: 22%;
            }
          }
        }
      }
    }
  }
}