body{
  font-family: 'Open Sans', sans-serif!important;
  line-height: 1.42857143!important;
}
.my-pagination {
  width: 100%;
  text-align: center;

  .pagination {
    margin-top: 3rem;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 1.2rem;
    gap: 5px;
    a{
      color: black!important;
    }
  }

  .page-num {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
    &:hover {
      background-color: #F0F2F5;
      color: white;
    }
  }
  .active {
    background-color: #F0F2F5;
    color: white;
  }
}

.border-1{
  a{
    display: flex;
    justify-content: center;
    .anticon{
      svg{
        margin-top: 2px;
        margin-left: 5px;
        font-size: 20px;
      }
    }
  }
}