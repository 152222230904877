.completeTask{
  .container-fluid{
    .fort-task-count{
      width: 100%;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
    }
       .row{
         .col-md-6{
           .card{
             display: flex;
             border: none;
             border-radius: 15px;
             background-color: #F0F2F5;
             margin: 10px 5px;
             max-height: 200px!important;
             transition: .1s linear;
             &:hover{
               transition: .1s linear;
               box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
               cursor: pointer;
             }
             .for-div{
               display: flex;
               .for-color{
                 display: flex;
                 border-radius: 15px 0 0 15px;
                 width: 15px;
                 height: 190px;
                 background-color: green;
               }
               .for-data{
                 padding: 10px 15px;
                 .for-title{
                   font-style: normal;
                   font-weight: 500;
                   font-size: 20px;
                   line-height: 21px;
                 }
                 .for-description{
                   font-style: normal;
                   font-weight: 500;
                   font-size: 16px;
                   line-height: 18px;

                 }
                 .for-endDate{
                   display: flex;
                   align-items: center;
                   margin-top: 20px;
                   p{
                     font-weight: 400;
                     display: flex;
                     margin-left: 15px;
                     align-items: center;
                     margin-top: 17px;
                   }
                 }
               }
             }
           }
         }
       }
     }
}