.table-for-options{
  position: absolute!important;
  opacity: 0;
  display:flex;
  margin-left: -4.2%;
  align-items: center;
  z-index: 1;
  border: none;
  background: transparent;
  p{
    margin-top: -8.2px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 41px;
    background:#001529 ;
    .anticon{
      color: white;
      cursor: pointer;
    }
    &:nth-child(1){
      background-color: #d9534f;
    }
  }
}
tbody, td, tfoot, th, thead, tr:hover .table-for-options{
  opacity: 1;
}

