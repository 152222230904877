.main-auth{
  .container-fluid{
    .row{
      min-height: 100vh;
      .col-md-12{
        background-image: url("../../img/background.png");
        background-size: 100% 100%!important;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        .border_in{
          justify-content: center;
          display: flex;
          box-shadow: .7px .3px 5px 1px white;
          border-radius: 15px;
          margin-top: -10%;
          position: absolute;
          //border: 2px solid white;
          width: 33%;
          height: 570px;
        }
        .main-style{
          .for-logo2{
            margin-top: 70px;
            display: flex;
            justify-content: center;
            img{
              width: 100px;
              height: 100px;
            }
          }
          .for-text{
            margin: 10% 0 15% 0;
            color: white;
            font-size: 25px;
            text-align: center;
          }
          .for-input{
            input{
              width:400px;
              height: 40px;
              border: none;
              outline: none;
              border-radius: 10px;
              padding: 0 15px;
            }
            .in-eyes{
              position:relative;
              .for-eyes{
                position: absolute;
                margin-top: 5%;
                margin-left: 90%;
                .anticon{
                  font-size: 25px;
                }
              }
            }
            .for-button{
              margin: 0 0 33% 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #cecece;
              width:400px;
              height: 40px;
              border: none;
              outline: none;
              border-radius: 10px;
              padding: 0 15px;
              cursor: pointer;
            }
            .for-button1{
              margin: 0 0 33% 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #9d9d9d;
              width:400px;
              height: 40px;
              border: none;
              outline: none;
              border-radius: 10px;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1200px) and (max-width: 1440px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top:50px;
            position: absolute;
            //border: 3px solid white;
            width: 33%;
            height:500px;
          }
          .main-style{
            .for-logo2{
              display: flex;
              justify-content: center;
              img{
                width: 100px;
                height: 100px;
              }
            }
            .for-text{
              margin: 10% 0 15% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 4.3%;
                  margin-left: 90%;
                  .anticon{
                    font-size: 20px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top: -7%;
            position: absolute;
            //border: 3px solid white;
            width: 38%;
            height: 500px;
          }
          .main-style{
            .for-logo2{
              display: flex;
              justify-content: center;
              img{
                width: 100px;
                height: 100px;
              }
            }
            .for-text{
              margin: 10% 0 15% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 4.3%;
                  margin-left: 90%;
                  .anticon{
                    font-size: 20px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top: -7%;
            position: absolute;
            //border: 3px solid white;
            width: 47%;
            height: 500px;
          }
          .main-style{
            .for-logo2{
              display: flex;
              justify-content: center;
              img{
                width: 100px;
                height: 100px;
              }
            }
            .for-text{
              margin: 10% 0 15% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 4.3%;
                  margin-left: 90%;
                  .anticon{
                    font-size: 20px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:350px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top: -7%;
            position: absolute;
            //border: 3px solid white;
            width: 47%;
            height: 500px;
          }
          .main-style{
            .for-logo2{
              display: flex;
              justify-content: center;
              img{
                width: 100px;
                height: 100px;
              }
            }
            .for-text{
              margin: 10% 0 15% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:270px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 6%;
                  margin-left: 90%;
                  .anticon{
                    font-size: 19px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:270px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:270px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 368px) and (max-width: 576px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top: -2%;
            position: absolute;
            //border: 3px solid white;
            width: 70%;
            height: 430px;
          }
          .main-style{
            .for-logo2{
              margin-top: 10%;
              display: flex;
              justify-content: center;
              img{
                width: 100px;
                height: 100px;
              }
            }
            .for-text{
              margin: 12% 0 13% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:250px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 7%;
                  margin-left: 90%;
                  .anticon{
                    font-size: 19px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:250px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:250px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 200px) and (max-width: 368px){
  .main-auth{
    .container-fluid{
      .row{
        min-height: 100vh;
        .col-md-12{
          background-image: url("../../img/background.png");
          background-size: 100% 100%!important;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .border_in{
            box-shadow: .7px .3px 5px 1px white;
            border-radius: 15px;
            margin-top: -7%;
            position: absolute;
            //border: 3px solid white;
            width: 70%;
            height: 79%;
          }
          .main-style{
            .for-logo2{
              display: flex;
              justify-content: center;
              img{
                width: 90px;
                height: 90px;
              }
            }
            .for-text{
              margin: 12% 0 13% 0;
              color: white;
              font-size: 22px;
              text-align: center;
            }
            .for-input{
              input{
                width:190px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
              .in-eyes{
                position:relative;
                .for-eyes{
                  position: absolute;
                  margin-top: 7%;
                  margin-left: 85%;
                  .anticon{
                    font-size: 19px;
                  }
                }
              }
              .for-button{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width:190px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
                cursor: pointer;
              }
              .for-button1{
                margin: 0 0 33% 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9d9d9d;
                width:190px;
                height: 30px;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}


.for-loader{
  width:100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.28);
  position: absolute;
  justify-content: center;
}
.lds-roller {
  margin-left: 48%;
  margin-top: 20%;
  align-items: center;
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





//@media(max-width: 1440px) {
//  .container-fluid{
//    width: 100%;
//    height: 100vh;
//    .row{
//      .col-md-12{
//        margin: 0;
//        box-sizing: border-box;
//        padding: 0!important;
//        width: 100%;
//        height: 100vh;
//        display: flex;
//        justify-content: center;
//        img{
//          width:100%;
//          height: 100%;
//        }
//        .main-style{
//          margin-top: -60%;
//          z-index: 1;
//          display: flex;
//          flex-direction: column;
//          justify-content: center;
//          .for-logo{
//            display: flex;
//            justify-content: center;
//            img{
//              margin-top: 4%;
//              width:200px;
//              height: 200px;
//            }
//          }
//          .for-text{
//            margin-top: 240px;
//            text-align: center;
//            color: white;
//            font-size: 30px;
//          }
//          .for-input{
//            margin-top: 4%;
//            display: flex;
//            flex-direction: column;
//            justify-content: center;
//            .for-eyes{
//              font-size: 23px;
//              cursor: pointer;
//              display: flex;
//              align-items: center;
//              justify-content: center;
//              margin-top: 1.5%;
//              margin-left: 58%;
//              position: absolute;
//              width: 20px;
//              height: 20px;
//            }
//            input{
//              margin-left: 39%;
//              padding: 0 10px;
//              border-radius: 15px;
//              border: none;
//              height: 40px;
//              width:400px;
//              outline: none;
//            }
//            .for-button{
//              margin-left: 39%;
//              border-radius: 10px;
//              border: none;
//              background-color: white;
//              display: flex;
//              justify-content: center;
//              align-items: center;
//              height: 40px;
//              width:400px;
//              cursor: pointer;
//              outline: none;
//            }
//          }
//        }
//      }
//    }
//  }
//}