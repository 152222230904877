.for_sort_date{
  width: 80%;
  height: 60px;
  margin-top: -25px;
  background-color: white;
  z-index: 2;
  display: flex;
  .react-datepicker-wrapper{
    .react-datepicker__input-container{
      input{
        outline: none!important;
        padding: 0 10px;
        margin-top: 13px;
        width: 300px;
        height: 45px;
        border-radius: 10px;
        border: 1px solid black;
        margin-left: 50px;
      }
    }
  }
.react-datepicker__navigation--next{
  width: 30px;
  margin-top: 2px;
 border: none;
}
  .react-datepicker__navigation--previous{
    margin-top: 2px;
    width: 30px;
    border: none;
  }
  button{
    margin-left: 5%;
    padding: 0 10px;
    margin-top: 13px;
    outline: none!important;
    border-radius: 8px;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 45px;

  }
}
.none_datePicker{
  display: none;
}
.div_in{
  display: flex;
}
@media (min-width: 1200px) and (max-width: 1440px){
  .for_sort_date{
    width: 80%;
    height: 60px;
    margin-top: -25px;
    background-color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        input{
          outline: none!important;
          padding: 0 10px;
          margin-top: 13px;
          width: 300px;
          height: 45px;
          border-radius: 10px;
        }
      }
    }
    .react-datepicker__navigation--next{
      margin-top: 2px;
      border:none!important;
      margin-left: 5px;
    }
    .react-datepicker__navigation--previous{
      margin-left: -5px;
      border:none!important;
      margin-top: 2px;
    }
    button{
      padding: 0 10px;
      margin-top: 13px;
      outline: none!important;
      border-radius: 8px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .for_sort_date{
    width: 80%;
    height: 60px;
    margin-top: -25px;
    background-color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        input{
          outline: none!important;
          padding: 0 10px;
          margin-top: 13px;
          width: 300px;
          height: 45px;
          border-radius: 10px;
          margin-left: 20px;
        }
      }
    }
    .react-datepicker__navigation--next{
      margin-top: 2px;
      border:none!important;
      margin-left: 5px;
    }
    .react-datepicker__navigation--previous{
      margin-left: -5px;
      border:none!important;
      margin-top: 2px;
    }
    button{
      padding: 0 10px;
      margin-top: 13px;
      outline: none!important;
      border-radius: 8px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin-left: 5%;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px){
  .for_sort_date{
    width: 80%;
    height: 60px;
    margin-top: -25px;
    background-color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        input{
          outline: none!important;
          padding: 0 10px;
          margin-top: 13px;
          width: 200px;
          height: 45px;
          border-radius: 10px;
          margin-left: 20px;
        }
      }
    }
    .react-datepicker__navigation--next{
      margin-top: 2px;
      border:none!important;
      margin-left: 5px;
    }
    .react-datepicker__navigation--previous{
      margin-left: -5px;
      border:none!important;
      margin-top: 2px;
    }
    button{
      padding: 0 10px;
      margin-top: 13px;
      outline: none!important;
      border-radius: 8px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      margin-left: 5%;
    }
  }
}
